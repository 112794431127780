<style scoped>
.home {
  width: 100%;
  height: 100%;
  background: #eee;
  background-size: 100% 100%;
}

.searchTop {
  width: 100%;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    display: flex;
    background: #de2810;
}

.searchMain {
  position: relative;
  display: flex;
  align-items: center;
}

.searchMain svg {
  fill: #ccc;
}

.secrchInput {
  flex: 1;
  padding: 5px 10px 5px 30px;
  border-radius: 50px;
  /* border: 1px solid #ccc; */
}

.secrchIcon {
  position: absolute;
  top: 60%;
  left: 15px;
  transform: translate(-50%, -50%);
}
.searchText{
  color: #fff;
  margin-left: 10px;
}

.filterBtn {
  width: 50px;
    height: 50px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.filterBtn svg {
  fill: #cf2223;
}

.header {
  width: 100%;
  text-align: center;
  font-size: 1.8rem;
  font-family: serif;
  font-weight: 800;
  padding-top: 0.5em;
  color: rgb(253, 245, 100);
}

.answerList {
  width: 100%;
  height: 66vh;
  overflow-y: scroll;
  padding: 0 17px;
}
.answerList .problemList {
  width: 100%;
    height: 70px;
    background: #fff;
    border-radius: 0.191rem;
    overflow: hidden;
    position: relative;
    margin: 15px 0;
}
.answerList .problemList .activityStatus {
  position: absolute;
    top: 35%;
    right: 0;
    width: 100px;
    height: 25px;
    color: #fff;
    letter-spacing: 5px;
    line-height: 25px;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    background-color: #ff7e00;
    border-radius: 50px 0 0 50px;
}
.answerList .problemList .activityStatus p {
  position: absolute;
  width: 130px;
  top: -30px;
  right: 0;
}
.answerList .problemTitle {
  width: calc(100% - 160px);
  height: 30px;
  text-align: left;
  padding-left: 5px;
  box-sizing: border-box;
  line-height: 34px;
  font-size: 16px;
  font-weight: 500;
  text-overflow: ellipsis; /*让截断的文字显示为点点。还有一个值是clip意截断不显示点点*/
  white-space: nowrap; /*让文字不换行*/
  overflow: hidden; /*超出要隐藏*/
}
.answerList .problemTime {
  text-align: left;
  padding-left: 8px;
  font-size: 12px;
  color: rgb(223, 83, 109, 200);
  margin-top: 10px;
  font-weight: 500;
}
.answerList .problemLine {
  width: 50%;
  height: 4px;
  margin: 16px 25% 0;
  border-radius: 2px;
}
.boxshow {
  box-shadow: 0px 0px 10px rgba(26, 98, 255, 0.05);
}

.middle {
  display: inline-block;
  width: 0px;
  height: 100%;
  vertical-align: middle;
}

/* 使用CSS3实现手机端右侧弹出效果，类似于van-popup */

.popup {
  position: fixed;
  top: 0;
  right: -100%; /* 初始状态隐藏在屏幕右侧 */
  width: 100%; /* 设置弹出窗口宽度 */
  height: 100%; /* 设置弹出窗口高度 */
  background-color: #fff; /* 设置背景颜色 */
  transition: right 0.3s ease; /* 添加过渡效果 */
  z-index: 999; /* 设置层级 */
}

.popup.active {
  right: 0; /* 弹出时右侧距离为0，显示在屏幕中 */
}

.typeList {
  width: 100%;
  margin: 0 auto;
}

.labelHeader {
  padding: 9px 0;
  background: #cf2223;
  color: #fff;
  box-shadow: inset 0 0 4px #607d8b;
}

.sysSetLabel {
  border-left: 2px solid #cf2223;
  background: white;
  align-items: center;
  display: flex;
  text-align: center;
  overflow: hidden;
  font-size: 1rem;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  font-weight: bold;
}

.parrentStyle {
  font-weight: bold;
}

.sysSetLabel .title {
  box-sizing: border-box;
  padding: 0px 5px 0px 10px;
  height: 24px;
  width: 96%;
  text-align: left;
}
.titleChildren {
  font-size: 0.8rem;
  background: white;
  border-radius: 10px;
  transition: 0.3s cubic-bezier(0, 0, 0.2, 1);
  margin: 0.5rem;
  text-align: center;
  overflow: hidden;
  padding: 10px 3px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.checkSpan {
  width: 18px;
  height: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #aaa;
  border-radius: 4px;
  margin-right: 2px;
  overflow: hidden;
  cursor: pointer;
}
.checkSpan svg {
  fill: #124660;
}
.filterSty{
  position: absolute;
    bottom: 12%;
    right: 5%;
  border-radius: 50%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.banner{
  width: 100%;
  height: 200px;
  line-height: 200px;
  background-image: url('../assets/banner.png');
  background-size: 100% 100%;
  font-size: 3rem;
    font-family: serif;
    font-weight: 800;
    color: rgb(253, 245, 100);
  
}
.tougaoback {
  padding: 0 20px;
  width: 100%;
  height: 3rem;
  background: #de2810;
  color: #fff;
  line-height: 3rem;
  font-size: 1.1rem;
  box-shadow: 0px 3px 3px rgba(30, 155, 204, 0.1);
  display: flex;
  /* margin-bottom: 0.5rem; */
}
</style>
<template>
  <div class="home">
    <div class="tougaoback">
      <div style="flex: 1; text-align: left">
       
      </div>
      <div style="flex: 1; text-align: center">活动列表</div>
      <div style="flex: 1; text-align: right"></div>
    </div>
    <!-- <div class="banner" style="display:inline-block;vertical-align: middle;">
        <p>活动列表</p>
      
      </div> -->

    <div class="searchTop">
      <div style="flex: 1" class="searchMain">
        <input
          class="secrchInput"
          placeholder="搜索活动名称"
          v-model="searchText"
        />

        <span class="secrchIcon">
          <svg
            t="1700727368422"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="13505"
            width="20"
            height="20"
          >
            <path
              d="M920.4 883.6L734.9 698.1c55.4-63.9 89.1-147 89.1-238.1 0-201-163-364-364-364S96 259 96 460s163 364 364 364c91.2 0 174.3-33.7 238.1-89.1l185.5 185.5c4.7 4.7 11.2 7.6 18.4 7.6 14.4 0 26-11.6 26-26 0-7.2-2.9-13.7-7.6-18.4zM460 772c-172 0-312-140-312-312s140-312 312-312 312 140 312 312-140 312-312 312z"
              p-id="13506"
            ></path>
          </svg>
        </span>
      </div>
      <div class="searchText"  @click="initData()">
        搜索
      </div>
    
    </div>
    <div class="filterSty">
        <div class="filterBtn" @click="showFilterType = true">
          <svg
            t="1700729417119"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="14588"
            width="25"
            height="25"
          >
            <path
              d="M809.301333 181.418667c24.746667-33.962667 24.746667-80.042667 0-113.834667-18.432-33.109333-52.394667-54.442667-90.112-57.002667H119.637333c-38.570667 0.170667-73.557333 22.357333-90.453333 57.002667-16.384 36.181333-16.384 77.653333 0 113.834667v8.192L226.304 433.493333c9.045333 14.848 14.677333 31.573333 16.384 48.810667v325.290667c-0.682667 10.922667 3.413333 21.674667 11.264 29.354666 7.850667 7.850667 18.773333 11.946667 29.866667 11.264 11.093333 0.682667 21.845333-3.413333 29.696-11.264 7.850667-7.68 11.946667-18.432 11.264-29.354666V482.133333c-2.048-34.816-13.482667-68.608-32.768-97.621333L103.082667 140.629333c-8.192-9.216-8.192-23.210667 0-32.597333 0-8.192 8.192-16.213333 24.576-16.213333H727.04c8.192 0 16.384 8.192 24.576 16.213333 3.925333 2.901333 6.144 7.338667 6.144 12.288 0 4.778667-2.218667 9.386667-6.144 12.288L546.304 376.32c-24.064 26.624-35.84 61.952-32.768 97.621333v496.128c-0.682667 10.922667 3.413333 21.674667 11.264 29.354667 7.850667 7.850667 18.602667 11.946667 29.696 11.264 11.093333 0.682667 21.845333-3.413333 29.866667-11.264 7.850667-7.68 11.946667-18.432 11.264-29.354667V473.941333c1.706667-17.237333 7.338667-33.962667 16.384-48.810666l197.12-244.053334 0.170666 0.341334z m0 0"
              p-id="14589"
            ></path>
            <path
              d="M891.392 758.613333H686.08c-11.093333-0.682667-21.845333 3.413333-29.696 11.264-7.850667 7.68-11.946667 18.432-11.264 29.354667-0.682667 10.922667 3.413333 21.674667 11.264 29.354667 7.850667 7.850667 18.602667 11.946667 29.696 11.264h205.312c11.093333 0.682667 21.845333-3.413333 29.696-11.264 7.850667-7.68 11.946667-18.432 11.264-29.354667 0.682667-10.922667-3.413333-21.674667-11.264-29.354667-7.850667-7.850667-18.602667-11.946667-29.696-11.264z m0-162.645333H686.08c-11.093333-0.682667-21.845333 3.413333-29.696 11.264-7.850667 7.68-11.946667 18.432-11.264 29.354667-0.682667 10.922667 3.413333 21.674667 11.264 29.525333 7.850667 7.850667 18.602667 11.946667 29.696 11.264h205.312c11.093333 0.682667 21.845333-3.413333 29.696-11.264 7.850667-7.68 11.946667-18.432 11.264-29.354667 0.682667-10.922667-3.413333-21.674667-11.264-29.525333-7.850667-7.850667-18.602667-11.946667-29.696-11.264z m-254.464-122.026667c-0.682667 10.922667 3.413333 21.674667 11.264 29.354667s18.773333 11.946667 29.866667 11.264h205.312c11.093333 0.682667 21.845333-3.584 29.696-11.264 7.850667-7.68 11.946667-18.432 11.264-29.354667 0-24.405333-8.192-40.618667-32.768-40.618666H686.08c-24.746667-2.048-46.421333 16.042667-49.152 40.618666z m0 0"
              p-id="14590"
            ></path>
          </svg>
        </div>
        <div
          :class="`popup ${showFilterType ? 'active' : ''}`"
          style="display: flex; flex-direction: column"
        >
          <div style="width: 100%">
            <h3 class="labelHeader">选择分类</h3>
          </div>
          <div
            style="
              position: relative;
              display: flex;
              flex:1
              justify-content: space-between;
              align-items: center;
              padding: 10px 15px;
              width: 100%;
              height: 100%;
              flex-direction: column;
            "
          >
            <div style="height: 90%; width: 100%; overflow-y: scroll">
              <div v-for="(item, index) in labelTypeList" :key="index">
                <div class="sysSetLabel activityType">
                  <div class="title">
                    {{ item.name }}
                  </div>
                </div>

                <div
                  class="childSty"
                  style="
                    display: flex;
                    flex-wrap: wrap;
                    gap: 10px;
                    align-items: center;
                    padding: 10px 0;
                  "
                >
                  <div
                    v-for="(childitem, childindex) in item.children"
                    :key="childindex"
                    @click="
                      chooseLabelList = chooseLabelList.includes(childitem.id)
                        ? chooseLabelList.filter(
                            (item) => item !== childitem.id
                          )
                        : [...chooseLabelList, childitem.id]
                    "
                    style="
                      display: flex;
                      gap: 1px;
                      align-items: center;
                      padding: 5px 0;
                    "
                  >
                    <span class="checkSpan">
                      <svg
                        v-if="chooseLabelList.indexOf(childitem.id) > -1"
                        t="1685354175209"
                        class="icon"
                        viewBox="0 0 1024 1024"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        p-id="2186"
                        width="12"
                        height="12"
                      >
                        <path
                          d="M63.222927 512c0 0 231.767598 189.584869 254.790964 350.823134 0 0 303.906591-497.491565 641.581264-542.003338 0 0-102.837156-74.943876-69.070098-193.395662 0 0-187.255825 18.684548-540.279067 566.637388L184.79375 413.212066 63.222927 512z"
                          p-id="2187"
                        ></path>
                      </svg>
                    </span>
                    <span class="syschar">{{ childitem.name }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              style="
                  display: flex;
    justify-content: space-evenly;
                margin-top: 20px;
                position: absolute;
                bottom: 50px;
                left: 0;
                right: 0;
                width: 100%;
              "
            >
              <Button
                type="info"
                @click="
                  showFilterType = false;
                  chooseTypeList(chooseLabelList);
                "
                >确定</Button
              >
              <Button type="error" @click="showFilterType = false">关闭</Button>
            </div>
          </div>
        </div>
      </div>
    <div class="typeList">
      <type-list
        @chooseTypeList="chooseTypeList"
        @chooseAllType="chooseAllType"
      ></type-list>
    </div>

    <div class="answerList">
      <div
        v-for="(problem, index) in problemList"
        :key="index"
        @click="goDetail(problem)"
        class="problemList boxshow"
      >
        <!-- 右上角活动状态 -->
        <!-- <div
          class="activityStatus"
          :style="
            `border-top: 30px solid rgb(${
              problem.begin ? '255, 193, 74' : '214,214,214'
            });`
          "
        > -->
        <div
          class="activityStatus"   
        >
        
            进行中
            <!-- {{ problem.begin ? "活动进行中" : "尚未开始" }} -->
          
        </div>
        <!-- 活动名称 -->
        <div
          class="problemTitle"
          style="border-left: 3px solid rgb(233, 106, 161)"
        >
          {{ problem.name }}
        </div>
        <!-- 活动时间 -->
        <div class="problemTime">
          {{ formatData(problem.updated) }}
        </div>
        <!-- 活动横杠 -->
        <!-- <div class="problemLine" :style="`background: rgb(199, 1, 16);`"></div> -->
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import wxLoginApi from "../api/wxLoginApi";
import { wxConPost } from "../api/httpApi"; // Data request API
import globalData from "../api/globalData";
import typeList from "../widgets/type.vue";

import common from "../api/common";

export default {
  name: "Home",
  data() {
    return {
      problemList: [],
      searchText: "",
      filter: "",
      showFilterType: false,
      chooseLabelId: null,
      labelTypeList: [],
      chooseLabelList: [],
    };
  },
  components: { typeList },
  methods: {
    // 选择标签
    chooseTypeList(labelId) {
      this.chooseLabelId = labelId;
      this.initData();
    },

    formatData(date) {
      return moment(date).format("YYYY年MM月DD日");
    },
    backMain() {
      this.$router.replace("/");
    },
    goDetail(problem) {
      localStorage.setItem("gtlt", problem.name);
      globalData.setActivity(problem);
      // this.$router.replace("/activity-main");
      this.$router.replace({
        path: "/activity-main",
        query: {
         name: problem.name,
         id:problem.id
        }})
        localStorage.setItem("activityId", problem.id);
    },

    //  分类组件初始化全部分类，用于编辑和添加
    chooseAllType(list) {
      this.labelTypeList = common.buildTree(list);
    },
    async initData() {
      const result = await wxConPost(
        "/services/gttt-answer-exam/wxActivity/getList",
        {
          filter: this.searchText,
          labelId: this.chooseLabelId,
        }
      );
      if (result.ret) {
        this.problemList = result.ret;
      }
    },
  },
  async created() {
    wxLoginApi.onReady(async () => {
      this.initData();
    });
  },
};
</script>
