

const labelObj = {}

const initLabelInfo = (labelList) => {
  for (const iterator of labelList) {
    labelObj[iterator.id] = iterator;
  }
}


const buildTree = (dataArr) => {
  const tree = [];
  const nodeMap = {};

  // 构建哈希表
  for (const iterator of dataArr) {
    const node = {
      value: iterator.id,
      label: iterator.name,
      ...iterator,
      children: [],
    };
    nodeMap[iterator.id] = node;
  }

  // 构建树形结构
  for (const iterator of dataArr) {
    const node = nodeMap[iterator.id];
    if (null != iterator.parentId) {
      const parentNode = nodeMap[iterator.parentId];
      parentNode.children.push(node);
    } else {
      tree.push(node);
    }
  }

  initLabelInfo(dataArr)
  return tree;
};



export const getNameByLableId = (id) => {
  return labelObj[id] || { name: "" };
};

export default {
  buildTree,
  initLabelInfo,
  getNameByLableId
};
