<style scoped>
.van-tabs {
  display: flex;
  /* border-radius: 4px; */
  overflow: hidden;
  background-color: #ec3115;
  font-weight: bold;

  /* border-radius: 4px; */
  overflow: hidden;
  font-family: Arial, sans-serif;
  max-height: 40px;
  overflow-x: auto;
  white-space: nowrap;
}

.van-tab {
  /* padding: 0 10px; */
  text-align: center;
  padding: 10px;
  cursor: pointer;
  color: #fff;
  transition: background-color 0.3s;
}

.van-tab.active {
  /* background-color: #f4f4f4; */
  color: #ffdd00;
}

.van-tab.activityBorder {
  border-bottom: 2px solid #ff5722;
}

.van-tab-content {
  padding: 10px;
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
}
</style>
<template>
  <div >
    <div class="van-tabs" style="justify-content: space-evenly;">
      <div
        v-for="(item, index) in labelList"
        :key="index"
        :class="['van-tab', activeTab == index ? 'active activityBorder' : '']"
        @click="chooseParent(item, index)"
      >
        {{ item.name }}
      </div>
    </div>

    <div class="van-tabs">
      <div
        v-for="(item, index) in childObj[curParent.id]"
        :key="index"
        :class="['van-tab', activeChildTab === index ? 'active' : '']"
        @click="
          activeChildTab = index;
          chooseList(item);
        "
      >
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import { wxConPost } from "../api/httpApi"; // Data request API

export default {
  name: "type",
  data() {
    return {
      labelList: [],
      curParent: {},
      childObj: {},
      activeTab: 0,
      activeChildTab: -1,
      currentTypeId: "",
    };
  },
  components: {},
  methods: {
    // 选中分类
    chooseList(item) {
      let labelId = [];
      if (item.name == "全部") {
        this.$emit("chooseTypeList", labelId);
        return;
      }
      if (item.parentId == null) {
        labelId = _.map(this.childObj[item.id], "id");
      } else {
        labelId = [item["id"]];
      }
      this.$emit("chooseTypeList", labelId);
    },

    chooseParent(item, index) {
      this.activeTab = index;
      this.activeChildTab = -1;
      this.curParent = item;

      if ("全部" == item.name) {
        this.$emit("chooseTypeList", null);
        return;
      }

      this.chooseList(item);
    },
    async initType() {
      this.labelList = [];
      this.childObj = {};

      try {
        const result = await wxConPost(
          "/services/gttt-answer-exam/activityType/getList"
        );
        this.$emit("chooseAllType", result.ret);
        const _dataList = result.ret;
        let _objList = {};
        for (const iterator of _dataList) {
          if (null == iterator.parentId) {
            this.labelList.push(iterator);
            _objList[iterator.id] = [];
          }
        }

        this.labelList.unshift({
          id: null,
          name: "全部",
        });

        for (const iterator of _dataList) {
          if (null !== iterator.parentId) {
            _objList[iterator.parentId].push(iterator);
          }
        }

        this.childObj = _objList;

        if (this.labelList.length > 0 && this.labelList[0]) {
          this.curParent = JSON.parse(JSON.stringify(this.labelList[0]));
        }

        // this.chooseList(this.curParent);

        this.$emit("chooseTypeList", null);
      } catch (e) {
        console.log("XXXXXXXXX Err", e);
      }
    },
  },
  created() {
    this.initType();
  },
};
</script>
